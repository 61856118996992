import { Carousel, Slide } from 'vue-carousel'
import moment from 'moment'
// import { groupBy } from 'lodash';
export default {
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            response: null,
            groupData: {},
            isLoading: true,
        }
    },
    created() {
        // this.getData();
        if (this.$store.state.auth.exp_data) {
            this.groupData = this.$store.state.auth.exp_data
            console.log(this.groupData);
            this.isLoading = false;
        } else {
            this.$router.push({
                name: '/home',
            })
        }
    },
    computed: {
        activeUser() {
            if (this.$store.getters['auth/getAuthenticatedUser'])
                return this.$store.getters['auth/getAuthenticatedUser']
            else return null
        },
        hasAttendingEvents() {
            if (this.activeUser) {
                return this.activeUser.my_attending_events.length > 0
            }
            return false
        },
        isHavingAnyExperience() {
            return (
                this.isHavingAnyPromotion ||
                this.isHavingAnyEvent ||
                this.isHavingAnyOffer
            )
        },
        isHavingAnyPromotion() {
            return (
                !this.isLoading &&
                'promotions' in this.groupData &&
                Object.keys(this.groupData.promotions).length > 0
            )
        },
        isHavingAnyEvent() {
            return (
                !this.isLoading &&
                'events' in this.groupData &&
                Object.keys(this.groupData.events).length > 0
            )
        },
        isHavingAnyOffer() {
            return (
                !this.isLoading &&
                'offers' in this.groupData &&
                Object.keys(this.groupData.offers).length > 0
            )
        },
    },
    methods: {
        getButtonText(value) {
            if (value) {
                if (value.type == 'offers') {
                    let end_date = moment(value.end_date, 'YYYY-MM-DD')
                    let current_date = moment()
                    return (
                        'Valid for ' +
                        end_date.diff(current_date, 'days') +
                        (end_date.diff(current_date, 'days') > 1
                            ? ' days'
                            : ' day')
                    )
                }
                return moment(value.occurence_dates).format('dddd')
            }
            return ''
        },
        gotonext(carouselname) {
            if (carouselname) {
                this.$refs[carouselname].goToPage(
                    this.$refs[carouselname].currentPage + 1
                )
            }
        },
        gotoprev(carouselname) {
            if (carouselname) {
                this.$refs[carouselname].goToPage(
                    this.$refs[carouselname].currentPage - 1
                )
            }
        },
        // activeUser() {
        //     return this.$store.getters['auth/getAuthenticatedUser']
        // },
        isUserApproved() {
            return this.activeUser.member_status === 'approved'
        },
        experienceDetails(val) {
            this.$router.push({
                name: `experiences_details`,
                params: {
                    type: val.type,
                    id: val.id,
                    event_data: val,
                },
            })
        },
    },
}
